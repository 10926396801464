import SectionWrapper from 'components/SectionWrapper/SectionWrapper';

function NotFound()
{
	return (
		<SectionWrapper view='Teapot' title='418' game>
			<h1>I'M A TEAPOT 🫖</h1>
		</SectionWrapper>
	);
}

export default NotFound;
