import SectionWrapper from 'components/SectionWrapper/SectionWrapper';

function AuthPage()
{
	return (
		<SectionWrapper view='AuthPage' title='WHO ARE YOU?' height='60vh'>
			<p style={{ padding: '1rem', textAlign: 'center' }}>
				you know this is a website about Simon, right?
			</p>
		</SectionWrapper>
	);
}

export default AuthPage;
